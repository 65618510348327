<template>
  <article
    :class="user_is_coordinator ? 'is-coordinator' : ''"
    class="view-dashboard"
    @click="clearToasts"
  >
    <div class="site-wrapper rythm-v">
      <header>
        <h1>Mes questions</h1>
      </header>

      <section class="section--questions rythm-v">
        <header>
          <h2>Liste des questions</h2>
        </header>

        <div class="toolbar" data-toolbar="questions">
          <router-link
            to="/edit-question-themes/0"
            :class="
              rows.length ? 'button--primary' : 'button--primary heartbeat'
            "
            v-if="!user_is_coordinator && !user_is_expert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="16"
              fill="none"
              class="icon"
              role="img"
            >
              <path
                fill="currentColor"
                d="M8.19579 1C8.19579 0.447715 7.78808 0 7.28515 0C6.78222 0 6.37451 0.447715 6.37451 1V7H0.910638C0.407707 7 0 7.44772 0 8C0 8.55229 0.407707 9 0.910638 9H6.37451V15C6.37451 15.5523 6.78222 16 7.28515 16C7.78808 16 8.19579 15.5523 8.19579 15V9H13.6596C14.1625 9 14.5702 8.55229 14.5702 8C14.5702 7.44772 14.1625 7 13.6596 7H8.19579V1Z"
              />
            </svg>
            <span class="caption">Nouvelle question</span>
          </router-link>
        </div>

        <div
          class="card shadow empty-state flex-col flex-center"
          v-show="!rows.length"
        >
          <span v-if="!user_is_coordinator && !user_is_coordinator"
            >Ajoutez une nouvelle question !</span
          >
          <span v-else>Aucune question pour le moment</span>
        </div>

        <div class="card shadow question-table-wrapper" v-show="rows.length">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :paginate="true"
            :pagination-options="{
              enabled: true,
              nextLabel: 'suivant',
              prevLabel: 'précédent',
              rowsPerPageLabel: 'lignes par page',
              ofLabel: 'sur',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'Toutes',
            }"
            :globalSearch="true"
            :search-options="{
              enabled: true,
              skipDiacritics: true,
              placeholder: 'Rechercher dans cette liste',
            }"
            styleClass="vgt-table striped"
            @on-column-filter="onColumnFilter"
          >
            >
            <template slot="table-column" slot-scope="props">
              <span v-if="props.column.field == 'actions'"></span>
              <span v-else>{{ props.column.label }}</span>
            </template>

            <template slot="table-row" slot-scope="props">
              <!-- Question title: link to question -->
              <router-link
                :to="`/question/${props.row.id}`"
                v-if="props.column.field == 'title'"
                >{{ props.formattedRow[props.column.field] }}</router-link
              >

              <!-- Question Status: value/caption -->
              <span
                v-else-if="props.column.field == 'status'"
                :class="`tag--${props.formattedRow[props.column.field].status}`"
              >
                {{ props.formattedRow[props.column.field].caption }}
              </span>

              <!-- Question assignment: not assigned -->
              <span
                v-else-if="props.column.field == 'expert' && !props.row.expert"
                :class="`tag--ongoing`"
              >
                Non affectée
              </span>

              <!-- Question assignment: assigned, update assignment button -->
              <button
                class="button--icon"
                v-else-if="props.column.field == 'actions' && props.row.expert"
                :disabled="props.row.status === 'complete'"
                @click="assignUserModal(props.row)"
              >
                <svg
                  role="img"
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2 19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <!-- Question assignment: unassigned, update assignment button -->
              <button
                class="button--icon-primary"
                v-else-if="props.column.field == 'actions' && !props.row.expert"
                :disabled="props.row.status === 'complete'"
                @click="assignUserModal(props.row)"
              >
                <svg
                  class="icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#404040"
                    fill-rule="evenodd"
                    d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <!-- Any other column (number, date, …) -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <div slot="emptystate">
              <p class="text-center">
                Aucune question ne correspond aux filtres sélectionnés.
              </p>
            </div>
          </vue-good-table>
        </div>
      </section>
    </div>

    <assign-expert-modal
      :open="modalAssignUserVisible"
      :users="experts"
      :question-id="this.modalAssignQuestionID"
      @modalClose="modalClose"
      @click="assignUser"
    />
  </article>
</template>

<style lang="scss">
@import "src/scss/dashboard.scss";
</style>

<script>
import { HTTP } from "../http-common.js";
import isMobile from "ismobilejs";
import { EventBus } from "../event-bus.js";

// Vue Good Table
// import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

// Assign Expert Modal
import AssignExpertModal from "../components/AssignExpertModal.vue";

// TODO: Remove LogRocket
import LogRocket from "logrocket";

// Dashboard component
export default {
  name: "dashboard",

  components: {
    VueGoodTable,
    AssignExpertModal,
  },

  data: function() {
    return {
      questions: [],
      experts: [],
      modalAssignUserVisible: false,
      modalAssignQuestionID: 0,
      statusFilterDropdownItems: [
        // NOTE: ATTENTION ESPACES INSÉCABLES !
        // {value: "draft", text: "Brouillon"},
        { value: "pending", text: "En attente de réponse" },
        { value: "ongoing", text: "Réponse disponible" },
        { value: "complete", text: "Terminé" },
      ],
      user_is_expert: false,
      user_is_expert_ext: false,
      user_is_coordinator: false,

      columns: [
        // {
        // 	label: "№",
        // 	field: "id",
        // 	type: "number",
        // 	sortable: true,
        // 	formatFn: this.zeroPadding,
        // 	tdClass: "question-number"
        // },
        {
          label: "Titre de la question",
          field: "title",
          sortable: true,
          tdClass: "question-title",
        },
        {
          label: "Date",
          field: "date_created",
          type: "date",
          dateInputFormat: "yyyy-MM-dd H:mm:s",
          dateOutputFormat: "dd/MM/yyyy H:mm",
          sortable: true,
          thClass: "vgt-left-align",
          tdClass: "vgt-left-align question-date",
        },
        {
          label: "Statut",
          field: "status",
          sortable: true,
          formatFn: this.formatQuestionStatus,
          tdClass: "question-status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Tout statut", // placeholder for filter input
            filterDropdownItems: [
              // NOTE: ATTENTION ESPACES INSÉCABLES !
              // {value: "draft", text: "Brouillon"},
              { value: "pending", text: "En attente de réponse" },
              { value: "ongoing", text: "Réponse disponible" },
              { value: "complete", text: "Terminé" },
            ],
          },
        },
      ],
      rows: [],
    };
  },

  computed: {
    // Current user data
    user: function() {
      return this.$store.state.userData;
    },
  },

  methods: {
    clearToasts: function() {
      this.$toasted.clear();
    },

    modalClose: function(event) {
      this.modalAssignUserVisible = false;
      this.modalAssignQuestionID = 0;
    },

    assignUser: function(user) {
      let payload = { questionID: this.modalAssignQuestionID, userID: user.id };

      this.$store
        .dispatch("POST_ASSIGN_EXPERT", payload)
        .then((res) => {
          // Toast
          let expert = this.experts.find((e) => e.id === user.id);
          let expertName = expert
            ? `${expert.firstname} ${expert.lastname}`
            : "";

          this.$toasted.global.appSuccess({
            message: `La question a été affectée à <strong>${expertName}</strong>.`,
          });

          // Update the questions (the table will react accordingly)
          this.getQuestions();
          this.modalClose();

          // Analytics
          EventBus.$emit(
            "trackEvent",
            "Affectation",
            `Utilisateur ${user.id} affecté à questionID ${this.modalAssignQuestionID} par userID ${this.user.id}`
          );
        })
        .catch((error) => {
          console.log("getQuestions error…", error);
        });
    },

    // assign user modal
    assignUserModal: function(row) {
      // NOTE: `row` = table row properties: id (question ID), (int) expert_id and (object) expert if any

      // Load experts then show modal
      this.$store
        .dispatch("GET_EXPERT_USERS", {})
        .then((experts) => {
          this.experts = experts;
          this.modalAssignUserVisible = true;
          this.modalAssignQuestionID = parseInt(row.id);
        })
        .catch((error) => {
          console.log("getQuestions error…", error);
        });
    },

    // Filters State
    onColumnFilter: function(params) {
      let previousFilters = this.$store.state.dashboardFilters || {};

      // Save current "status" filter
      if (typeof params.columnFilters.status === "undefined")
        params.columnFilters.status = "";
      previousFilters.status = params.columnFilters.status;

      // Save current "expert" filter (assignments)
      if (typeof params.columnFilters.expert === "undefined")
        params.columnFilters.expert = "";
      previousFilters.expert = params.columnFilters.expert;

      // Update store
      this.$store.dispatch("STORE_DASHBOARD_FILTERS", previousFilters);
    },

    // Table cell click
    handleCellClick: function(params) {
      let field = params.column.field;

      // Open question
      let questionID = params.row.id;
      if (!questionID) return console.error("no question id!");
      this.$router.push(`/question/${questionID}`);
    },

    // Open question
    onQuestionClick: function(params) {
      let questionID = params.row.id;
      if (!questionID) return console.error("no question id!");
      this.$router.push(`/question/${questionID}`);
    },

    // Tri sur les assignements (états, expert)
    sortAssignmentRow: function(x, y, col, rowX, rowY) {
      // Order by expert name
      if (!!rowX.expert && !!rowY.expert) {
        if (rowX.expert.lastname < rowY.expert.lastname) return -1;
        if (rowX.expert.lastname > rowY.expert.lastname) return 1;
        return 0;
      }

      // Order by expert / no expert
      return rowX.expert_id ? -1 : 1;
    },

    // Tri sur les médecins émandeurs
    sortOwnerRow: function(x, y, col, rowX, rowY) {
      // Order by owner user name
      if (!!rowX.owner && !!rowY.owner) {
        if (rowX.owner.lastname < rowY.owner.lastname) return -1;
        if (rowX.owner.lastname > rowY.owner.lastname) return 1;
        return 0;
      }
    },

    // Retourne le nom de l'expert assigné / propriétaire de la question
    formatUserRow: function(value) {
      return `${value.firstname} ${value.lastname}`;
    },

    // Retourne le libellé du statut
    formatQuestionStatus: function(value) {
      let statuses = this.statusFilterDropdownItems;
      let s = statuses.find((item) => {
        return item.value === value;
      });
      if (!s) return { status: "", caption: "?" };
      return { status: value, caption: s.text };
    },

    // Zero padding
    zeroPadding: function(value) {
      return `${value}`.padStart(2, "0");
    },

    // Move search and filter elements to toolbar…
    setupToolbar: function() {
      // Move elements around…
      let toolbar = document.querySelector('[data-toolbar="questions"]');
      let search = document.querySelector(".vgt-global-search");
      let filters = document.querySelectorAll(".vgt-select");
      toolbar.appendChild(search);
      filters.forEach((el) => {
        toolbar.appendChild(el);
      });
    },

    // setupTable
    setupTable: function() {
      var userID = this.user.id;

      // Is the user an expert? (role 2)
      this.user_is_expert = this.user.roles.indexOf(2) !== -1;
      // Is the user an expert extended? (role 5)
      this.user_is_expert_ext = this.user.roles.indexOf(5) !== -1;
      // Is the user a coordinator? (role 3)
      this.user_is_coordinator = this.user.roles.indexOf(3) !== -1;

      // Coordinator: display assignments and question owner
      if (this.user_is_coordinator || this.user_is_expert_ext) {
        let assignmentCol = {
          label: "Affectations",
          field: "expert",
          formatFn: this.formatUserRow,
          sortable: true,
          sortFn: this.sortAssignmentRow,
          tdClass: "assignment-status",
          filterOptions: {
            enabled: true, // enable filter for this column
            // NOTE: ATTENTION ESPACES INSÉCABLES !
            placeholder: "Toute affectation", // placeholder for filter input
            filterDropdownItems: [
              "Affectées",
              "Affectées à moi",
              "Non affectées",
            ],
            filterFn: function(data, filterString) {
              if (filterString === "Non affectées") {
                // Data should be empty/undefined
                return !data;
              }

              // Les miennes
              else if (filterString === "Affectées à moi") {
                return !!data && parseInt(data.id) === parseInt(userID);
              }

              // Affectées
              else {
                // Data should be a non empty expert-user object
                return !!data;
              }
            },
            filterValue: "",
          },
        };

        // Question owner
        this.columns.push({
          label: "Médecin",
          field: "owner",
          formatFn: this.formatUserRow,
          sortable: true,
          sortFn: this.sortOwnerRow,
          tdClass: "owner-user",
        });

        // Assigned expert column
        this.columns.push(assignmentCol);

        // Action buttons (last col on desktop, both sides on mobile)
        // Coordinators only
        if (!this.user_is_expert_ext) {
          let actionsColumn = {
            label: "Action",
            field: "actions",
            sortable: false,
          };

          if (isMobile(window.navigator).any)
            this.columns = [actionsColumn, ...this.columns];
          this.columns.push(actionsColumn);
        }
      }

      // Setup filters
      this.setupTableFilters();
    },

    // Récupération des questions
    getQuestions: function() {
      this.$store
        .dispatch("GET_QUESTIONS", {})
        .then((questions) => {
          this.questions = questions;
          // Fill up the table
          this.rows = this.questions;
        })
        .catch((error) => {
          console.log("getQuestions error…", error);
        });
    },

    // Setup table filters upon user role
    setupTableFilters: function() {
      // NOTE: no draft at all for now
      /*
			// Remove "draft" filter for experts and coordinators
			if (this.user_is_coordinator || this.user_is_expert) {
				let column = this.columns.find(col => col.field === "status");
				let statuses = [...column.filterOptions.filterDropdownItems];
				let draftIndex = statuses.findIndex(el => el.value === "draft");
				if (draftIndex !== false) {
					statuses.splice(draftIndex, 1);
					this.$set(column.filterOptions, "filterDropdownItems", statuses);
				}
			}
			*/

      // Remove "complete" filter for coordinators
      // if (this.user_is_coordinator) {
      // 	let column = this.columns.find(col => col.field === "status");
      // 	let statuses = [...column.filterOptions.filterDropdownItems];
      // 	let completeIndex = statuses.findIndex(el => el.value === "complete");
      // 	if (completeIndex !== false) {
      // 		statuses.splice(completeIndex, 1);
      // 		this.$set(column.filterOptions, "filterDropdownItems", statuses);
      // 	}
      // }

      // Filters State
      // Load previous filter value if any
      let previousFilters = this.$store.state.dashboardFilters;
      let column = null;

      if (previousFilters) {
        // Question assignment filter ("expert" field)
        column = this.columns.find((col) => col.field === "expert");
        if (column)
          this.$set(
            column.filterOptions,
            "filterValue",
            previousFilters.expert
          );

        // Question status filter
        column = this.columns.find((col) => col.field === "status");
        if (column)
          this.$set(
            column.filterOptions,
            "filterValue",
            previousFilters.status
          );
      }

      // No filter saved, set default filter for extended experts:
      else {
        if (this.user_is_expert_ext) {
          // Question assignment filter ("expert" field)
          column = this.columns.find((col) => col.field === "expert");
          if (column)
            this.$set(column.filterOptions, "filterValue", "Affectées à moi");
        }
      }
    },
  },

  mounted() {
    // Load questions
    this.getQuestions();

    // Setup the table columns, rows, filters, …
    this.setupTable();

    if (!!this.user) {
      // TODO: Remove LogRocket
      LogRocket.identify(parseInt(this.user.id), {
        name: `${this.user.firstname} ${this.user.lastname}`,
        email: this.user.email,
        // custom data
        roles: this.user.roles,
        is_verified: this.user.is_verified,
        is_active: this.user.is_active,
        registration_complete: this.user.registration_complete,
      });
    }
  },

  updated() {
    // Setup toolbar
    this.setupToolbar();
  },
};
</script>
